import React from 'react';
// import dotenv from "dotenv"
import ReactDOM from 'react-dom/client';
// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

import AppRoutes from "./AppRoutes";
import reportWebVitals from './reportWebVitals';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./App.css"

// dotenv.config()

// Sentry.init({
//   dsn: "https://fc9901538bb442bc99653545f7a8955c@o913885.ingest.sentry.io/5879096",
//   integrations: [new Integrations.BrowserTracing()],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//   environment: process.env.REACT_APP_ENV || 'development',
// });

ReactDOM.createRoot(document.getElementById('root')).render(
  <AppRoutes />,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
