import React, { createContext, useCallback, useContext, useReducer } from "react";

import { PICKUP_LOCATIONS } from "../../constants";
import { Delivery } from "../../Models";

import api from "../Api";

export const CONSTANTS = {
  FETCH_BY_TOKEN: "FETCH_BY_TOKEN",
  SET_BODY: "SET_BODY",
  RESET_BODY: "RESET_BODY",
};

const INITIAL_STATE = {
  delivery: {},
  body: {
    isDoorKey: true,
    doorKey: "",
    pickupLocation: PICKUP_LOCATIONS[0].value,
    pickupLocationText: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_BY_TOKEN:      
      return {
        ...state,
        delivery: action.delivery,
      };
    case CONSTANTS.SET_BODY:      
      return {
        ...state,
        body: {
          ...state.body,
          ...action.body,
        },
      };
    case CONSTANTS.RESET_BODY:      
      return {
        ...state,
        body: INITIAL_STATE.body,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDeliveryStore = () => {
  const { dispatch, state } = useContext(Context);

  const fetchByToken = useCallback(async ({ token }) => {
    const delivery = await api.post(`/public/deliveries/by-token`, { token });

    dispatch({ 
      type: CONSTANTS.FETCH_BY_TOKEN, 
      delivery: new Delivery(delivery),
    });
  }, [api.post, dispatch]);

  const setBody = useCallback(
    (body = {}) => {      
      dispatch({ type: CONSTANTS.SET_BODY, body });
    },
    [dispatch]
  );

  const resetBody = useCallback(
    () => {      
      dispatch({ type: CONSTANTS.RESET_BODY });
    },
    [dispatch]
  );

  const updateDelivery = useCallback(
    async (bookId, data) => await api.put(`/public/deliveries/${bookId}/places`, data),
    [api.put]
  );

  return {
    state,
    fetchByToken,
    setBody,
    updateDelivery,
    resetBody,
  };
};
